<template>
  <div class="columns content">
    <div class="column is-9">
      <router-link :to="`/clubs/${club.id}/edit`" tag="h2" class="clickable">
        {{club.name}}
        <i class="far fa-edit has-text-primary" style="font-size:1.2rem"></i>
      </router-link>
      <p v-if="club.is_deleted"><b>DELETED</b></p>
      <p v-else-if="club.is_disabled"><b>DISABLED</b></p>
      <b>Test Club:</b> {{club.is_test_club}} (this is not editable)<br />
      <b>Mailing Address:</b> {{club.config.mailing_address}}<br />
      <b>City/State/Country:</b> {{ club.config.city }} {{ club.config.state }} {{ club.config.country }}<br />
      <b>Timezone:</b> {{club.timezone}}<br />
      <b>Abbreviation:</b> {{club.abbreviation}}<br />
      <b>Zendesk Club Name:</b> {{ club.zendesk_club_name }}<br/>
      <b>Website:</b> {{club.website}}<br />
      <b>Email Domain:</b> {{club.email_domain}}<br />
      <b>Contact:</b> {{club.contact_name}}<br />
      <b>Contact Email:</b> {{club.contact_email}}<br />
      <b>Ages:</b> {{club.config.ages.join(', ')}}<br />
      <b>Genders:</b> {{club.config.genders.join(', ')}}<br/>
      <b>Sports:</b> {{club.sports.map((s) => s.sport).join(', ')}}<br/>
      <b>Registration Url:</b>
      <input class="input is-small"
        type="text"
        :value="club.registration_url"
        readonly
        style="width:20rem">
      <br/>
      <b>Game Prefix:</b> {{ club.config.game_prefix }}<br/>
      <b>Enable SMS:</b> {{ club.config.enable_sms }}<br/>
      <b>Enable Forms:</b> {{ club.config.enable_forms }}<br/>
      <b>Enable Billing:</b> {{ club.config.enable_billing }}<br/>
      <b>   Enable Drill Tags:</b> {{ club.config.enable_drill_tags }}<br/>
      <b>Enable Testing:</b> {{ club.config.enable_testing }}<br/>
      <b>Enable Matchup Import/Export:</b> {{ !!club.config.enable_league_matchup_export }}<br/>
      <b>Enable Legacy Game Import:</b> {{ !!club.config.enable_legacy_game_import }}<br/>
      <b>Dictionary:</b> {{ club.config.dictionary }}<br/>
      <b>Custom Player Fields:</b> {{ club.config.custom_player_fields }}<br/>
      <b>Custom Team Fields:</b> {{ club.config.custom_team_fields }}<br/>
      <b>Onboarding Type:</b> {{ club.config.onboarding_type }}<br/>
      <b>Status:</b> {{ club.config.onboarding_status }}<br/>
      <b>Competitive Player Cap:</b> {{ club.config.competitive_player_cap }}<br/>
      <b>Recreational Player Cap:</b> {{ club.config.rec_player_cap }}<br/>
      <b>Enable New Drill Designer:</b> {{ club.config.enable_new_drill_designer }}<br/>
    </div>
    <div class="column is-3">
      <div
        class="image-container clickable"
        @click="editImageModal = !editImageModal">
        <img
          v-if="club.image_url"
          :src="club.image_url"
          class="image">
        <div
          :class="{'opacity': !club.image_url, 'image-text-middle': club.image_url}">
          <b-field>
            <b-upload
              :disabled="uploading"
              drag-drop
              v-model="file"
              accept="image/png, image/jpeg"
            >
              <section class="section">
                <div class="content has-text-centered">
                  <p>
                    <b-icon
                      icon="upload"
                      size="is-large">
                    </b-icon>
                  </p>
                  <p>{{ club.image_url ? 'Change' : 'Add'}} Image</p>
                </div>
              </section>
            </b-upload>
          </b-field>
        </div>
      </div>
    </div>
  </div>
</template>`

<script>
import api from '@/http-playmetrics';

export default {
  props: {
    club: { type: Object, required: true },
  },

  data() {
    return {
      editImageModal: false,
      uploading: false,
      file: null,
    };
  },

  watch: {
    file() {
      this.imageChanged(this.file[0]);
    },
  },

  methods: {
    imageChanged(file) {
      api().uploadUserFile(file).then((url) => {
        const clubWithNewImage = { image_url: url };
        api().post(`/admin/clubs/${this.$route.params.club}`, clubWithNewImage)
          .then(() => {
            this.$store.dispatch('createNotification', { message: 'Logo updated', success: true, toast: true });
            this.$emit('imageUpdated');
            this.uploading = false;
          });
      }).catch(() => {
        this.uploading = false;
      });
    },
  },
};
</script>

<style scoped>
  .image-container {
    position: relative;
  }
  .image {
    opacity: 1;
    display: block;
    width: 100%;
    height: auto;
    transition: .5s ease;
    backface-visibility: hidden;
  }
  .image-text-middle {
    transition: .5s ease;
    opacity: 0;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    text-align: center;
  }
  .image-container:hover .image {
    opacity: 0.1;
  }
  .image-container:hover .image-text-middle {
    opacity: 1;
  }
  .opacity {
    opacity: 1;
  }
</style>
